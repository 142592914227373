import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormInputComponent } from './form-input.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [FormInputComponent],
  exports: [FormInputComponent],
  imports: [CommonModule, FormsModule, IonicModule, ReactiveFormsModule],
})
export class FormInputModule {}
