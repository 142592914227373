import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ReplaceProductPageRoutingModule } from './replace-product-routing.module';

import { ReplaceProductPage } from './replace-product.page';
import { ReplaceProductViewComponent } from '../../components/replace-product-view/replace-product-view.component';
import { SharedModule } from '../../shared-module/shared-module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReplaceProductPageRoutingModule,
    SharedModule,
    TranslateModule,
  ],
  declarations: [ReplaceProductPage, ReplaceProductViewComponent],
  exports: [ReplaceProductPage],
})
export class ReplaceProductPageModule {}
