import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastController: ToastController) {}

  public async notify(msg) {
    const toast = await this.toastController.create({
      message: msg,
      cssClass: 'backend-success-toast',
      duration: 2000,
    });
    await toast.present();
  }

  public async notifyError(msg) {
    const toast = await this.toastController.create({
      message: msg,
      cssClass: 'backend-error-toast',
      duration: 2000,
    });
    await toast.present();
  }
}
