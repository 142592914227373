import { Component, Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class ModalNavigationGuard implements CanDeactivate<Component> {
  constructor(private modalCtrl: ModalController) {}

  async canDeactivate() {
    try {
      await this.modalCtrl.dismiss();
      // eslint-disable-next-line no-restricted-globals
      history.pushState(null, null, location.href);
      // eslint-disable-next-line no-console
      console.log('modal closed');
      return false;
      // eslint-disable-next-line no-empty
    } catch (e) {}
    return true;
  }
}
