import { EventEmitter, Injectable } from '@angular/core';
import _ from 'lodash';
import { Fridge } from '../../models/Fridge';
import { FridgeDoorStatus } from 'package-types';
import {
  FridgeStateMachine,
  FridgeStateMachineStates,
  InstallType,
} from 'package-types/src/devices';
import { Api } from '../api/api.service';
import { UserService } from '../user/user.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ModalController } from '@ionic/angular';
import { SocketService } from '../socket/socket.service';
import { TranslateService } from '@ngx-translate/core';
import Utils from 'src/app/utils';

@Injectable({
  providedIn: 'root',
})
export class FridgeService {
  constructor(
    public router: Router,
    public notificationService: NotificationService,
    public socketService: SocketService,
    public modalController: ModalController,
    public userService: UserService,
    public i18n: TranslateService,
  ) {}

  activeFridge: Fridge | null = null;
  public fridges: Fridge[];
  inventoryProcess = false;
  multiSection = false;
  doorIsOpened: Map<number, boolean> = new Map();
  showWeightSensorsValues = false;
  weightValues: { [key: string]: { pins: number[]; weight: number; sectionId: string } };
  streamer: {
    showFrames: boolean;
    frames: { timestamp: string; src: string }[];
    packetNumber: number;
  } = {
    showFrames: false,
    frames: [],
    packetNumber: 0,
  };

  public stateChangeEvent = new EventEmitter<{ fridgeId: string; state: FridgeStateMachine }>();

  public setActiveFridge(fridge, door?: { status: FridgeDoorStatus; rackNumber: number }) {
    this.activeFridge = fridge;
    this.multiSection = fridge.maxProductSlotsOnSection && fridge.maxProductSlotsOnSection > 1;
    this.inventoryProcess = this.activeFridge.status === 'service';
    if (door) {
      this.doorIsOpened.set(door.rackNumber, door.status === FridgeDoorStatus.OPENED);
    }
    this.showWeightSensorsValues = false;
  }

  public setFridges(fridges) {
    this.fridges = fridges.map((f) => {
      f.merchantName = this.userService.merchants.find((m) => m._id === f.merchantId)?.name || '';
      return f;
    });
  }

  public stopSensorDiagnostic() {
    this.showWeightSensorsValues = false;
  }

  public changeSubSystemState(_id, state) {
    const activeIndex = this.findFridgeId(_id);
    if (activeIndex !== -1) {
      try {
        this.fridges[activeIndex].subSystemsState = {
          ...this.fridges[activeIndex].subSystemsState,
          ...state,
        };
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }

    if (this.activeFridge?._id === _id) {
      this.activeFridge.subSystemsState = {
        ...this.activeFridge.subSystemsState,
        ...state,
      };
    }
  }

  public changeTemperature(_id, temperature: string) {
    const activeIndex = this.findFridgeId(_id);
    const newTemperature: Fridge['temperature'] = {
      value: temperature,
      parsed: parseFloat(temperature),
      stale: false,
    };
    if (activeIndex !== -1) {
      try {
        this.fridges[activeIndex].temperature = newTemperature;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }

    if (this.activeFridge?._id === _id) {
      this.activeFridge.temperature = newTemperature;
    }
  }

  public changeDoorState(firdgeId, doorState: FridgeDoorStatus, rackNumber: number) {
    this.doorIsOpened.set(rackNumber, doorState === FridgeDoorStatus.OPENED);
  }

  public changeFridgeConnection(_id, state) {
    const activeIndex = this.findFridgeId(_id);
    if (activeIndex !== -1) {
      try {
        this.fridges[activeIndex].connected = state;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }

    if (this.activeFridge?._id === _id) {
      this.activeFridge.connected = state;
    }
  }

  public changeFridgeState(_id, state) {
    const activeIndex = this.findFridgeId(_id);
    if (activeIndex !== -1) {
      try {
        this.fridges[activeIndex].stateMachine = state;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }

    if (this.activeFridge?._id === _id) {
      this.activeFridge.stateMachine = state;
      // TODO: Use enum here
      this.inventoryProcess = state.state === 'Service';
    }

    this.stateChangeEvent.emit({ fridgeId: _id, state });
  }

  public filterFridges(searchQuery: string): Fridge[] {
    if (searchQuery.length === 0) {
      return this.fridges;
    }
    return _.filter(this.fridges, (fridge) => {
      return (
        fridge.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
        fridge.inventoryNumber.toString().includes(searchQuery)
      );
    });
  }

  public getFridgeById(_id) {
    return this.fridges[_.findIndex(this.fridges, { _id })];
  }

  public findFridgeId(_id) {
    return _.findIndex(this.fridges, { _id });
  }
  // FIXME
  public async checkLabelingState(
    installId,
    productId,
    type: 'weighting' | 'recording',
    notifyError: boolean = false,
    inModal: boolean = false,
  ) {
    switch (type) {
      case 'weighting':
        if (
          this.getFridgeById(installId)?.stateMachine?.state === FridgeStateMachineStates.Weighing
        ) {
          const weighting = await Api.getWeightingProgress({
            installId,
            installType: InstallType.Fridge,
          });
          if (weighting.productId === productId) {
            await this.router.navigate(['weighting', installId, productId]);
            return false;
          } else {
            if (notifyError) {
              await this.notificationService.notifyError(
                this.i18n.instant('notification.error.busy_weight'),
              );
            }
            return !inModal;
          }
        } else {
          return true;
        }
      case 'recording':
        if (
          this.getFridgeById(installId)?.stateMachine?.state ===
            FridgeStateMachineStates.Recording ||
          this.getFridgeById(installId)?.stateMachine?.state ===
            FridgeStateMachineStates.WaitForEndRecording
        ) {
          const recording = await Api.getRecordingProgress({
            installId,
            installType: InstallType.Fridge,
          });
          if (recording.labelProductId === productId) {
            await this.router.navigate(['recording', installId, productId]);
            return false;
          } else {
            if (notifyError) {
              await this.notificationService.notifyError(
                this.i18n.instant('notification.error.busy_record'),
              );
            }
            return !inModal;
          }
        } else {
          return true;
        }
    }
  }
  getActiveFridgeCurrency() {
    return Utils.getCurrencySymbol(this.activeFridge?.currency?.toUpperCase());
  }
}
