<div class="confirmation-modal-wrapper">
  <img src="assets/icon/cross orange.svg" (click)="select(false)" alt="" />
  <div>
    <div class="title">{{ title }}</div>
    <div class="text">{{ text }}</div>
  </div>
  <div class="actions">
    <div class="btn" (click)="select(false)">No</div>
    <div class="btn filled" (click)="select(true)">yes</div>
  </div>
</div>
