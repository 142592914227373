import { Injectable } from '@angular/core';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { omit } from 'lodash';

import { AI, Products, Files, Gateway, InstallType } from 'package-types';
import {
  BoGetProductPlacementResult,
  BoRequestFridgeDoorStatusResult,
  BoGetFridgeNetworkTrafficResult,
  BoUpdateFridgeSettingsArguments,
} from 'package-types/devices/actions';
import { DevicesWeighing } from 'package-types/devices/events';
import { BoSafeFridge, Installation } from 'package-types/devices';

import { User } from '../user/user.service';

import { Fridge } from '../../models/Fridge';
import { BaseProduct, Product } from '../../models/Product';
import { SectionWithStock } from 'src/app/models/SectionWithStock';
import { Merchant } from '../../models/Merchant';

export type FetchProductsParams = Omit<
  Products.Actions.MerGetProductsArguments['params'],
  'merProductIds' | 'merchantId'
> & { merProductIds?: string[]; merchantId?: string };

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public login(
    credentials: Gateway.Actions.BoLoginArguments['params'],
  ): Promise<Omit<Gateway.Actions.BoLoginResult, 'user'> & { user: User }> {
    return axios.post('/auth/boLogin', credentials);
  }

  public getUserByToken(): Promise<{ user: User }> {
    return axios.get('/backOffice/user');
  }

  public async getAllFridges(): Promise<Fridge[]> {
    const data: { fridges: BoSafeFridge[] } = await axios.get('/devices/bo/fridges');

    // @ts-ignore
    return data.fridges.map((f) => {
      return this.parseTemperature(f);
    });
  }

  public async getFridgeById(id: string): Promise<Fridge> {
    const data: { fridge: BoSafeFridge } = await axios.get(`/devices/bo/fridge/${id}`);

    // @ts-ignore
    return this.parseTemperature(data.fridge);
  }

  public getProductPlacement(id: string): Promise<BoGetProductPlacementResult> {
    return axios.get(`/devices/bo/productPlacement/Fridge/${id}`);
  }

  public replaceProduct(
    productId: string,
    sectionId: string,
    position: number,
  ): Promise<{ section: SectionWithStock }> {
    return axios.post(`/devices/bo/productPlacement/section/${sectionId}/product/${position}`, {
      productId,
    });
  }

  public removeSectionProduct(
    sectionId: string,
    position: number,
  ): Promise<{ section: SectionWithStock }> {
    return axios.delete(`/devices/bo/productPlacement/section/${sectionId}/product/${position}`);
  }

  public addProductToSection(productId, sectionId) {
    return axios.post(`/devices/bo/productPlacement/section/${sectionId}`, { productId });
  }

  public getProducts(
    ids: string[],
    merchantId: string | null,
    productName: string = '',
  ): Promise<Products.Actions.BoGetProductsBackOfficeResult> {
    if (!merchantId) {
      return axios.get(`/products/mer/products`, {
        params: { merProductIds: ids, productName: productName || undefined },
      });
    }
    return axios.get(`/products/mer/products`, {
      params: { merProductIds: ids, merchantId, productName: productName || undefined },
    });
  }

  getMerProduct(merchantId: string, productId: string): Promise<{ product: Product }> {
    return axios.get(`/products/mer/product/${productId}`, { params: { merchantId } });
  }

  getBaseProduct(productId: string): Promise<{ product: BaseProduct }> {
    return axios.get(`/products/bo/product/${productId}`);
  }

  updateMerProduct(id: string, product): Promise<{ product: Product }> {
    return axios.put(`/products/mer/product/${id}`, product);
  }

  createMerProduct(
    product: Products.Actions.BoCreateProductArguments['params'],
  ): Promise<{ product: Product }> {
    return axios.post(`/products/mer/product`, product);
  }

  uploadBaseProductImage(formData: FormData): Promise<{ fileId: string }> {
    return axios.post(`/file/bo/uploadProductImage`, formData);
  }

  public getFilteredProducts(params: FetchProductsParams): Promise<{ products: Product[] }> {
    return axios.get(`/products/mer/products`, { params });
  }

  public getPaginatedProducts(
    params: {
      skip: number;
      limit: number;
    },
    merchantId: string | null,
  ): Promise<{ products: Product[] }> {
    if (!merchantId) {
      return axios.get(`/products/mer/products`, { params });
    }
    return axios.get(`/products/mer/products`, { params: { ...params, merchantId } });
  }

  public getImages(ids: string[]): Promise<Files.Actions.GetProductImagesLinksResult> {
    if (ids.length === 0) {
      // @ts-ignore
      return { imagesLinks: {} };
    }
    return axios.get(`/file/images`, { params: { imageIds: ids } });
  }

  public openFridge(id: string, rackId?: string): Promise<{ success: boolean }> {
    return axios.post(`/devices/bo/fridge/${id}/open`, { rackId });
  }

  public changeQuantity(
    sectionId,
    productId,
    quantity,
    position,
  ): Promise<{ section: SectionWithStock }> {
    return axios.put(`/devices/bo/inventory/product/quantity`, {
      sectionId,
      productId,
      quantity,
      position,
    });
  }
  // TODO: ADD interface for POS response
  public executeFridgeCommand(fridgeId: string, command: string): Promise<any> {
    return axios.post(`/devices/bo/fridge/${fridgeId}/command`, {
      command,
    });
  }

  public setZero(fridgeId: string): Promise<{ success: boolean }> {
    return axios.post(`/devices/bo/shelfController/${InstallType.Fridge}/${fridgeId}/setZero`);
  }

  public executeAiCommand(fridgeId: string, command: string): Promise<{ success: boolean }> {
    return axios.post(`/devices/bo/fridge/${fridgeId}/aiCommand`, {
      command,
    });
  }

  public getDoorStatus(
    fridgeId: string,
    rackNumber: number,
  ): Promise<BoRequestFridgeDoorStatusResult> {
    return axios.get(`/devices/bo/fridge/${fridgeId}/doorStatus?rackNumber=${rackNumber}`);
  }

  getCategories(
    params: Products.Actions.BoGetCategoriesArguments['params'],
  ): Promise<Products.Actions.BoGetCategoriesResult> {
    return axios.get('/products/bo/categories', { params });
  }

  getMerchants(): Promise<{ merchants: Merchant[] }> {
    return axios.get(`/stores/bo/merchants`);
  }

  startWeightingProcess(data): Promise<{ success: boolean }> {
    return axios.post(`/devices/bo/weighing/start`, data);
  }

  stopWeightingProcess(data): Promise<{ success: boolean }> {
    return axios.post(`/devices/bo/weighing/stop`, data);
  }

  getWeightingProgress(
    params: Omit<Installation, 'installId'> & { installId: string },
  ): Promise<Omit<DevicesWeighing, 'productId'> & { productId: string }> {
    return axios.get(`/devices/bo/weighing/progress`, { params });
  }

  confirmWeighingResult(id, confirm): Promise<DevicesWeighing> {
    return axios.post(`/devices/bo/weighing/confirmResult`, {
      installType: InstallType.Fridge,
      installId: id,
      confirm,
    });
  }

  startRecordingProcess(data): Promise<{ success: boolean }> {
    return axios.post(`/devices/bo/recording/start`, data);
  }

  stopRecordingProcess(data): Promise<{ success: boolean }> {
    return axios.post(`/devices/bo/recording/stop`, data);
  }

  getRecordingProgress(params): Promise<any> {
    return axios.get(`/devices/bo/recording/progress`, { params });
  }

  confirmRecordingResult(params): Promise<DevicesWeighing> {
    return axios.post(`/devices/bo/recording/confirm`, params);
  }

  setFridgeSettings(
    params: Omit<BoUpdateFridgeSettingsArguments['params'], 'fridgeId'>,
    fridgeId,
  ): Promise<{ fridge: Fridge }> {
    return axios.post(`/devices/bo/fridge/${fridgeId}/settings`, params);
  }

  setPosIp(fridgeId, ip): Promise<void> {
    return axios.put(`/devices/bo/fridges/${fridgeId}/hardwareConfig/devices/pos`, { ip });
  }

  getFridgeNetworkTraffic(fridgeId): Promise<BoGetFridgeNetworkTrafficResult> {
    return axios.get(`/devices/bo/fridge/${fridgeId}/networkTraffic`);
  }

  createCategory(
    data: Products.Actions.BoCreateCategoryArguments['params'],
  ): Promise<Products.Actions.BoCreateCategoryResult> {
    return axios.post('/products/bo/category', data);
  }

  getCombos(merchantId): Promise<any> {
    return axios.get(`/products/bo/comboOffers`, { params: { merchantId } });
  }

  createCombo(combo): Promise<any> {
    return axios.post(`/products/bo/comboOffers`, combo);
  }

  updateCombo(combo, id): Promise<any> {
    return axios.put(`/products/bo/comboOffers/${id}`, combo);
  }

  removeCombo(id): Promise<any> {
    return axios.delete(`/products/bo/comboOffers/${id}`);
  }

  getRecognitionClasses(
    params: AI.Actions.BoGetRecognitionClassesArguments['params'],
  ): Promise<AI.Actions.BoGetRecognitionClassesResult> {
    return axios.get(`/ai/bo/recognitionClasses`, {
      params,
      paramsSerializer: (p) => {
        return qs.stringify(p, { encode: false });
      },
    });
  }

  private parseTemperature(
    f: BoSafeFridge,
  ): Omit<BoSafeFridge, 'temperature'> | Fridge['temperature'] {
    let temperature: Fridge['temperature'];
    if (f.temperature) {
      const minutes = moment.duration(moment().diff(f.temperature.date)).asMinutes();
      if (minutes < 60) {
        temperature = {
          stale: minutes > 5,
          value: f.temperature.value as unknown as string,
          parsed: parseFloat(f.temperature.value as unknown as string),
        };
      }
    }
    return {
      ...omit(f, 'temperature'),
      ...(temperature ? { temperature } : {}),
    };
  }
}

export const Api = new ApiService();
