<ion-header>
  <ion-row class="ion-align-items-center">
    <div class="column">
      <div class="back" (click)="onBack()">
        <img src="assets/icon/back.svg" alt="" />
      </div>
      <div class="logo">{{ title }}</div>
    </div>
  </ion-row>
</ion-header>
