import { EventEmitter, Injectable } from '@angular/core';
import { BackOffice, Gateway, RoleCode } from 'package-types';
import { Api } from '../api/api.service';
import { Products } from 'package-types/products';
import { Merchant } from 'src/app/models/Merchant';

export type User = Omit<BackOffice.SafeUserWithPermissions, 'merchantId'> & {
  merchantId?: string | null;
};
@Injectable({
  providedIn: 'root',
})
export class UserService {
  public userData: User;
  public merchants: Merchant[] = [];
  public loginEvent = new EventEmitter<void>();
  public roleCode = RoleCode;

  public async loginUser(credential: Gateway.Actions.BoLoginArguments['params']) {
    const userData = await Api.login({
      ...credential,
      ...{ appName: Gateway.AppNames.FridgeServiceMan },
    });
    localStorage.setItem('token', userData.boAuthToken);
    localStorage.setItem('expiresAt', userData.tokenExpiresAt.toString());
    this.userData = userData.user;
    this.loginEvent.emit();
  }

  public logoutUser() {
    localStorage.removeItem('token');
    localStorage.removeItem('expiresAt');
    this.merchants = [];
  }

  public setUserData(data: User) {
    this.userData = data;
  }

  public get isAuthenticated(): boolean {
    return !!localStorage.getItem('token');
  }

  public get isRoot() {
    return this.userData.roles?.includes(RoleCode.Root);
  }

  public get haveMerchant() {
    return !!this.userData.merchantId;
  }

  public getMerchantById(merId: string) {
    return this.merchants.find((m) => m._id === merId);
  }

  public userMerchant(): Merchant | null | undefined {
    if (!this.userData.merchantId) {
      return null;
    }

    return this.getMerchantById(this.userData.merchantId);
  }

  public getUserData() {
    return this.userData;
  }

  public setMerchants(merchants: Merchant[]) {
    this.merchants = merchants;
  }

  hasPermissionOrRoot(permission) {
    return (
      this.userData.roles?.includes(RoleCode.Root) ||
      this.userData.permissions?.includes(permission)
    );
  }
}
