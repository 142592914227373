import _ from 'lodash';

import { Files, Products } from 'package-types';
import { RackWithProducts } from 'package-types/src/devices';

import { EventEmitter, Injectable, Output } from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';
import { Api, FetchProductsParams } from '../api/api.service';
import { UserService } from '../user/user.service';

import { Product } from 'src/app/models/Product';
import { SectionWithStock } from 'src/app/models/SectionWithStock';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  public activeProducts: Products.Actions.BoGetProductsBackOfficeResult['products'];
  public imagesLinks: Files.Images.IdDimensionsLinks;
  public productPlacement: RackWithProducts[];
  public loaded = false;
  public paginatedProducts: Product[] = [];
  public paginatedImagesLinks: Files.Images.IdDimensionsLinks = {};
  public sortedCategories: any[] = [];
  public categories: Products.Category[] = [];
  public activeProductIds: string[];
  public activeClassIds: string[];
  @Output() productUpdated: EventEmitter<Product> = new EventEmitter();

  constructor(public userService: UserService) {}

  public products: {
    products: Product[];
    imagesLinks: Files.Images.IdDimensionsLinks;
  } = { products: [], imagesLinks: {} };
  public activeProduct: string | null = null;

  public async getProductPlacement(id: string) {
    const { racks } = await Api.getProductPlacement(id);
    this.productPlacement = racks;
    this.sortPlacement();
    this.activeProductIds = _.chain(
      this.productPlacement.map((r) => r.shelves.map((sh) => sh.sections.map((s) => s.productIds))),
    )
      .flattenDeep()
      .uniq()
      .value();
  }

  public async getProductsWithImages(merchantId) {
    this.loaded = false;

    const { products } = await Api.getProducts(this.generateIds(), merchantId);
    this.activeProducts = products;

    const { imagesLinks } = await Api.getImages(
      _.flatten(_.map(this.activeProducts, (product: Products.Product) => product.images || [])),
    );

    this.imagesLinks = imagesLinks;
    this.activeClassIds = this.activeProducts
      .filter((ap) => this.activeProductIds.includes(ap._id))
      .map((p) => p.aiId);
    this.loaded = true;
  }

  public getProductFromStore(id: string) {
    return _.find(this.activeProducts, { _id: id }) as Product;
  }

  public getShelfWithSection(sectionId) {
    for (const rack in this.productPlacement) {
      for (const shelf of this.productPlacement[rack].shelves) {
        for (const section of shelf.sections) {
          if (section._id === sectionId) {
            return { shelf, section };
          }
        }
      }
    }
    return {};
  }

  public resetPagination() {
    this.paginatedProducts = [];
    this.paginatedImagesLinks = {};
  }

  public async getPaginatedProducts(params, infiniteScroll: IonInfiniteScroll, merchantId) {
    const { products } = await Api.getPaginatedProducts(params, merchantId);
    this.paginatedProducts = [...this.paginatedProducts, ...products];

    const { imagesLinks } = await Api.getImages(
      _.flatten(_.map(this.paginatedProducts, (product: Products.Product) => product.images || [])),
    );
    this.paginatedImagesLinks = { ...this.paginatedImagesLinks, ...imagesLinks };

    if (infiniteScroll) { await infiniteScroll.complete(); }

    if (products.length < params.limit && infiniteScroll) {
      infiniteScroll.disabled = true;
    }
  }

  public replaceSection(sectionToReplace: SectionWithStock) {
    for (const rack in this.productPlacement) {
      _.each(this.productPlacement[rack].shelves, (shelf, iShelf) => {
        _.each(shelf.sections, (section, iSection) => {
          if (section._id === sectionToReplace._id) {
            // @ts-ignore
            this.productPlacement[rack].shelves[iShelf].sections[iSection] = sectionToReplace;
          }
        });
      });
    }
  }

  public sortPlacement() {
    for (const rack in this.productPlacement) {
      if (this.productPlacement[rack] && this.productPlacement[rack].shelves) {
        this.productPlacement[rack].shelves = _.orderBy(
          this.productPlacement[rack].shelves,
          (item) => item.number,
          ['asc'],
        );
      }
    }
  }

  public async getFilteredProducts(params: FetchProductsParams) {
    const { products } = await Api.getFilteredProducts(params);

    const { imagesLinks } = await Api.getImages(
      _.flatten(_.map(products, (product: Products.Product) => product.images || [])),
    );

    return { products, imagesLinks };
  }

  public storeProduct(product: Product) {
    this.products.products.unshift(product);
  }

  public updateProduct(product: Product) {
    this.productUpdated.emit(product);
  }

  async setMerchantId(merchantId: string) {
    if (!merchantId) {
      return this.setCategories([]);
    }
    let params: Products.Actions.BoGetCategoriesArguments['params'] = {
      merchantId: merchantId as any,
    };
    if (merchantId === this.userService.userData.merchantId) {
      params = {};
    }
    const { categories } = await Api.getCategories(params);
    this.setCategories(categories);
  }

  public setCategories(categories: Products.Category[]) {
    const sortedCategories = [];
    for (const category of categories) {
      if (category.parentPath === '/') {
        sortedCategories.push({
          name: category.name,
          _id: category._id,
          path: category.categoryPath.split('/')[1],
          innerCategories: [],
        });
      }
    }

    for (const category of categories) {
      for (const sorted of sortedCategories) {
        if (category.parentPath.split('/')[1] === sorted.path) {
          sorted.innerCategories.push({
            name: category.name,
            _id: category._id,
          });
        }
      }
    }

    this.categories = categories;
    this.sortedCategories = sortedCategories;
  }

  async createCategory(params: {
    name: string;
    parentPath?: string;
    merchantId: string;
  }): Promise<Products.Category> {
    const { category } = await Api.createCategory(
      params as any as Products.Actions.BoCreateCategoryArguments['params'],
    );
    this.setCategories([...this.categories, category]);
    return category;
  }

  private generateIds() {
    const products: string[] = [];
    if (this.productPlacement.length > 0) {
      for (const rack in this.productPlacement) {
        for (const shelf of this.productPlacement[rack].shelves) {
          for (const section of shelf.sections) {
            if (section.productIds) {
              for (const productId of section.productIds) {
                if (!products.includes(productId as unknown as string)) {
                  products.push(productId as unknown as string);
                }
              }
            }
          }
        }
      }
    }
    return products;
  }
}
