<ion-app>
  <ion-router-outlet animated="false" [swipeGesture]="false" *ngIf="!loading">
    <!-- <h2>{{ 'HELLO' | translate }}</h2> -->
    <!-- <select #langSelect (change)="translate.use(langSelect.value)">
      <option
        *ngFor="let lang of translate.getLangs()"
        [value]="lang"
        [selected]="lang === translate.currentLang"
      >
        {{ lang }}
      </option>
    </select> -->
  </ion-router-outlet>
  <div class="loader" *ngIf="loading"><ion-spinner name="crescent"></ion-spinner></div>
</ion-app>
