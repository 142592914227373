<div class="product-view">
  <img [src]="image" alt="" />
  <div class="product-info">
    <div class="name">
      {{ product.name }}
      <span class="weight">{{ product.weight }}g</span>
    </div>
    <div class="select" (click)="emitReplace()">
      <span *ngIf="isAdd">{{ i18n.instant('replace_product.add') }}</span>
      <span *ngIf="!isAdd">{{ i18n.instant('replace_product.select') }}</span>
    </div>
  </div>
</div>
