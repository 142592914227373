<ion-header>
  <ion-row class="ion-align-items-center">
    <div class="column">
      <div class="back" (click)="back()">
        <img src="assets/icon/back.svg" alt="" />
      </div>
      <div class="logo">
        <span>Select Product</span>
      </div>
    </div>
  </ion-row>
</ion-header>
<ion-content>
  <ion-row class="search-wrap ion-justify-content-between ion-align-items-center">
    <div *ngIf="!isSearch">Search Products</div>
    <app-search-wrap
      class="search"
      (close)="closeSearch()"
      (onSearch)="search($event)"
      title=""
    ></app-search-wrap>
  </ion-row>

  <div
    class="products"
    *ngIf="productService.paginatedImagesLinks && productService.paginatedProducts && !isSearch"
  >
    <app-simple-product
      *ngFor="let product of productService.paginatedProducts"
      [product]="product"
      [image]="
        product.images && productService.paginatedImagesLinks[product.images[0]]
          ? productService.paginatedImagesLinks[product.images[0]].thumbnail
          : ''
      "
      (select)="
        selectProduct(
          $event,
          product.images && productService.paginatedImagesLinks[product.images[0]]
            ? productService.paginatedImagesLinks[product.images[0]]
            : null
        )
      "
    ></app-simple-product>
  </div>

  <div
    class="products"
    *ngIf="isSearch && filteredProducts && filteredProducts.products.length > 0"
  >
    <app-simple-product
      *ngFor="let product of filteredProducts.products"
      [product]="product"
      [image]="
        product.images && filteredProducts.imagesLinks[product.images[0]]
          ? filteredProducts.imagesLinks[product.images[0]].thumbnail
          : ''
      "
      (select)="
        selectProduct(
          $event,
          product.images && filteredProducts.imagesLinks[product.images[0]]
            ? filteredProducts.imagesLinks[product.images[0]]
            : null
        )
      "
    ></app-simple-product>
  </div>

  <ion-infinite-scroll (ionInfinite)="doInfinite()" [disabled]="isSearch">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
