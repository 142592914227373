import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from '../../models/Product';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-replace-product-view',
  templateUrl: './replace-product-view.component.html',
  styleUrls: ['./replace-product-view.component.scss'],
})
export class ReplaceProductViewComponent implements OnInit {
  constructor(public i18n: TranslateService) {}
  @Input('product') product: Product;
  @Input('image') image: string;
  @Input('isAdd') isAdd = false;

  @Output() replace: EventEmitter<string> = new EventEmitter();

  ngOnInit() {}

  emitReplace() {
    this.replace.emit(this.product._id);
  }
}
