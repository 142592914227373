<div class="product-view">
  <img [src]="image" alt="" />
  <div class="product-info">
    <div class="name">
      {{ product.name }}
      <span class="weight">{{ product.weight }}g</span>
    </div>
    <div class="select" (click)="emitSelect()">
      <span>Select</span>
    </div>
  </div>
</div>
