import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IonInput } from '@ionic/angular';

@Component({
  selector: 'app-search-wrap',
  templateUrl: './search-wrap.component.html',
  styleUrls: ['./search-wrap.component.scss'],
})
export class SearchWrapComponent {
  isSearch = false;
  searchQuery = '';
  @ViewChild('searchInput') searchInput: IonInput;

  @Input() title: string;
  @Output() open: EventEmitter<void> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() back: EventEmitter<void> = new EventEmitter();
  @Output() onSearch: EventEmitter<string> = new EventEmitter();

  search() {
    this.onSearch.emit(this.searchQuery);
  }

  public reset() {
    this.isSearch = false;
    this.searchQuery = '';
  }

  async openSearch() {
    this.isSearch = true;
    await this.searchInput.setFocus();
    this.open.emit();
  }

  async closeSearch() {
    this.searchQuery = '';
    // TODO: fix
    //await this.searchInput.setBlur();
    this.isSearch = false;
    this.close.emit();
  }

  onBack() {
    this.back.emit();
  }
}
