import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { UserService } from 'src/app/services/user/user.service';
import Utils from 'src/app/utils';
import { SelectProductModalComponent } from '@components/select-product-modal/select-product-modal.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Api } from 'src/app/services/api/api.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-edit-combo-modal',
  templateUrl: './edit-combo-modal.component.html',
  styleUrls: ['./edit-combo-modal.component.scss'],
})
export class EditComboModalComponent implements OnInit {
  @Input() comboToEdit;
  @Input() merchantId;
  @Input() products;
  @Input() images;

  comboPrice: number;
  comboPriceChange: Subject<number> = new Subject();
  utils = Utils;

  comboForm = {
    merchantId: '',
    products: [],
  };

  localImages = {};
  localProducts = [];
  activeMerchant;
  isEdit = false;

  constructor(
    private modalCtrl: ModalController,
    public userService: UserService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    // Debounce combo price changes to prevent excessive recalculations
    this.comboPriceChange.pipe(debounceTime(300)).subscribe(() => {
      this.recalculateComboPrices();
    });

    if (this.comboToEdit) {
      this.isEdit = true;
      this.comboForm.merchantId = _.cloneDeep(this.comboToEdit.merchantId);
      this.comboForm.products = _.cloneDeep(this.comboToEdit.products);
      this.comboPrice = parseFloat(this.calculateInitialComboPrice());
    } else {
      this.isEdit = false;
      this.comboForm.merchantId = this.merchantId;
    }

    this.activeMerchant = this.userService.getMerchantById(this.merchantId);

    this.localProducts = this.products;
    this.localImages = this.images;
  }

  get activeProducts() {
    const comboProductIds = this.comboForm.products.map(
      (comboProduct) => comboProduct.merProductId,
    );

    const seenProductIds = new Set<string>();

    return this.products
      .filter((product) => comboProductIds.includes(product._id))
      .filter((product) => {
        if (seenProductIds.has(product._id)) {
          return false;
        }
        seenProductIds.add(product._id);
        return true;
      })
      .map((product) => ({
        ...product,
        comboProduct: this.comboForm.products.find(
          (comboProduct) => comboProduct.merProductId === product._id,
        ),
      }));
  }

  get totals() {
    let originalTotal = 0;
    let comboTotal = 0;

    this.comboForm.products.forEach((product) => {
      comboTotal += parseFloat(product.price) * product.quantity;
      originalTotal +=
        parseFloat(this.localProducts.find((p) => p._id === product.merProductId).price) *
        product.quantity;
    });

    return {
      originalTotal: originalTotal.toFixed(2),
      comboTotal: comboTotal.toFixed(2),
      factor: new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }).format((1 - comboTotal / originalTotal) * -1),
    };
  }

  recalculateComboPrices() {
    if (this.comboPrice <= 0 || isNaN(this.comboPrice)) {
      return;
    }

    const originalTotal = this.comboForm.products.reduce((total, product) => {
      const originalProduct = this.localProducts.find((p) => p._id === product.merProductId);
      if (originalProduct) {
        const productQuantity = product.quantity || 1;
        return total + parseFloat(originalProduct.price) * productQuantity;
      }
      return total;
    }, 0);

    if (originalTotal > 0) {
      const priceFactor = this.comboPrice / originalTotal;

      this.comboForm.products.forEach((product) => {
        const originalProduct = this.localProducts.find((p) => p._id === product.merProductId);
        if (originalProduct) {
          product.price = (parseFloat(originalProduct.price) * priceFactor).toFixed(2);
        }
      });
    }
  }

  calculateInitialComboPrice() {
    let total = 0;
    this.comboForm.products.forEach((product) => {
      const productQuantity = product.quantity || 1;
      total += parseFloat(product.price) * productQuantity;
    });
    return total.toFixed(2);
  }

  async back() {
    await this.modalCtrl.dismiss();
  }

  async openProductModal() {
    const modal = await this.modalCtrl.create({
      component: SelectProductModalComponent,
      componentProps: {
        merchantId: this.merchantId,
      },
    });
    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data) {
      const { product, image } = data.productData;
      if (this.comboForm.products.find((p) => p.merProductId === product._id)) {
        this.notificationService.notifyError('Product already in combo');
        return;
      }

      this.localProducts.push(product);
      if (image) this.localImages = { ...this.localImages, ...{ [product.images[0]]: image } };

      this.comboForm.products.push({
        merProductId: product._id,
        quantity: 1, // Default quantity
        price: product.price,
      });

      this.recalculateComboPrices(); // Recalculate prices after adding a new product
    }
  }

  increaseQuantity(product) {
    const selectedProduct = this.comboForm.products.find((p) => p.merProductId === product._id);
    if (selectedProduct) {
      selectedProduct.quantity += 1;
      this.recalculateComboPrices();
    }
  }

  decreaseQuantity(product) {
    const selectedProduct = this.comboForm.products.find((p) => p.merProductId === product._id);
    if (selectedProduct && selectedProduct.quantity > 1) {
      selectedProduct.quantity -= 1;
      this.recalculateComboPrices();
    }
  }

  remove(index) {
    this.comboForm.products.splice(index, 1);
    this.recalculateComboPrices();
  }

  async save() {
    if (this.isEdit) {
      const { comboOffer } = await Api.updateCombo(
        { products: this.comboForm.products },
        this.comboToEdit._id,
      );

      await this.modalCtrl.dismiss({
        action: 'edit',
        comboOffer,
        newProducts: this.localProducts,
        newImages: this.localImages,
      });

      this.notificationService.notify('Combo was created');
    } else {
      const { comboOffer } = await Api.createCombo(this.comboForm);
      await this.modalCtrl.dismiss({
        action: 'create',
        comboOffer,
        newProducts: this.localProducts,
        newImages: this.localImages,
      });
      this.notificationService.notify('Combo was updated');
    }
  }
}
