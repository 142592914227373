import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShelfWithProducts } from 'package-types/devices';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { PlatformLocation } from '@angular/common';
import { FridgeService } from '../../services/fridge/fridge.service';
import { ProductService } from '../../services/product/product.service';
import { Api } from '../../services/api/api.service';
import { SectionWithStock } from '../../models/SectionWithStock';
import { ConfirmationModalComponent } from '@components/confirmation-modal/confirmation-modal.component';
import { NotificationService } from '../../services/notification/notification.service';
import { UserService } from 'src/app/services/user/user.service';
import { Files } from 'package-types';
import { Product } from '../../models/Product';

@Component({
  selector: 'app-replace-product',
  templateUrl: './replace-product.page.html',
  styleUrls: ['./replace-product.page.scss'],
})
export class ReplaceProductPage implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  constructor(
    public fridgeService: FridgeService,
    public productService: ProductService,
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public modalController: ModalController,
    private notificationService: NotificationService,
    private location: PlatformLocation,
  ) {
    location.onPopState(() => this.closeModal.emit());
  }

  @Input() sectionId: string;
  @Input() rackNumber = 1;
  @Input() isAdd = false;
  @Input() isReplaceUnknown = false;
  @Input() position = 0;
  @Output() closeModal = new EventEmitter<any>();
  @Output() goBack = new EventEmitter<any>();
  activeShelf: ShelfWithProducts;
  activeSection: SectionWithStock;
  filteredProducts: { imagesLinks: Files.Images.IdDimensionsLinks; products: Product[] };
  skip = 0;
  limit = 15;
  isSearch = false;
  debounce;

  async ngOnInit() {
    if (!this.fridgeService.activeFridge) {
      await this.router.navigate(['/home']);
    }
    this.productService.resetPagination();
    const { section, shelf } = this.productService.getShelfWithSection(this.sectionId);
    this.activeShelf = shelf;
    // @ts-ignore
    this.activeSection = section;

    await this.productService.getPaginatedProducts(
      { skip: this.skip, limit: this.limit },
      this.infiniteScroll,
      this.userService.userData.merchantId ? null : this.fridgeService.activeFridge.merchantId,
    );
  }

  async back() {
    if (this.isAdd || this.isReplaceUnknown) {
      await this.modalController.dismiss();
    }
    this.goBack.emit();
  }

  async doInfinite() {
    this.skip += this.limit;
    await this.productService.getPaginatedProducts(
      { skip: this.skip, limit: this.limit },
      this.infiniteScroll,
      this.userService.userData.merchantId ? null : this.fridgeService.activeFridge.merchantId,
    );
  }

  async replaceProduct(product: Product) {
    const isUserConfirmed = await this.showConfirmationModal(product);
    if (!isUserConfirmed) {
      return;
    }

    let data;

    if (this.isAdd) {
      data = await Api.addProductToSection(product._id, this.activeSection._id);
    } else {
      data = await Api.replaceProduct(product._id, this.activeSection._id, this.position);
    }

    await this.productService.replaceSection(data.section);
    await this.productService.getProductsWithImages(
      this.userService.userData.merchantId ? null : this.fridgeService.activeFridge.merchantId,
    );
    this.productService.resetPagination();
    await this.closeModal.emit();
    await this.modalController.dismiss();
    await this.notificationService.notify(
      !this.isAdd
        ? 'The product has been changed successfully.'
        : 'The product has been added successfully',
    );
  }

  async showConfirmationModal(product: Product): Promise<boolean> {
    const isWeightBelowThreshold =
      product?.weight &&
      this.activeSection.sectionThreshold &&
      this.activeSection.sectionThreshold >= product.weight;

    const title = isWeightBelowThreshold
      ? '⚠️ Product weight is less than the sensitivity threshold. This may lead to reduced detection quality.'
      : this.getRegularAddProductTitle(this.isAdd);

    const message = this.isAdd
      ? 'Click “Yes” to add the product. “NO” to keep old product affiliation.'
      : 'Click “Yes” to continue replacing. “NO” to keep old product affiliation.';

    const { success } = await ConfirmationModalComponent.show(this.modalController, title, message);

    return success;
  }

  getRegularAddProductTitle(isAdd: boolean): string {
    return isAdd ? 'Want to add a new product?' : 'Want to change the product?';
  }

  closeSearch() {
    this.isSearch = false;
    this.filteredProducts.products = [];
    this.filteredProducts.imagesLinks = {};
  }

  async search(productName) {
    clearTimeout(this.debounce);
    if (productName.length === 0) {
      this.closeSearch();
      return;
    }
    this.debounce = setTimeout(async () => {
      this.isSearch = true;
      this.filteredProducts = await this.productService.getFilteredProducts({
        skip: 0,
        limit: 999,
        productName,
        merchantId: this.userService.userData.merchantId
          ? null
          : (this.fridgeService.activeFridge.merchantId as any),
      });
    }, 300);
  }
}
