<ion-header>
  <ion-row class="ion-align-items-center">
    <div class="column">
      <div class="back" (click)="back()">
        <img src="assets/icon/back.svg" alt="" />
      </div>
      <div class="logo">
        <span *ngIf="isEdit">Edit Combo</span>
        <span *ngIf="!isEdit">Add Combo</span>
      </div>
    </div>
  </ion-row>
</ion-header>

<ion-content>
  <ion-item class="default-input">
    <ion-input
      [(ngModel)]="comboPrice"
      (ngModelChange)="comboPriceChange.next($event)"
      [placeholder]="'Enter Combo price'"
      label="Expected Combo Price"
      labelPlacement="stacked"
      type="number"
    >
    </ion-input>
  </ion-item>

  <!-- Totals -->
  <div>
    <p>Original Total: {{ utils.formatMoneyByMerchant(totals.originalTotal, activeMerchant) }}</p>
    <p>
      Combo Total: {{ utils.formatMoneyByMerchant(totals.comboTotal, activeMerchant) }} ({{
        totals.factor
      }})
    </p>
  </div>

  <!-- Product List -->
  <div class="product-view" *ngFor="let product of activeProducts; let i = index">
    <div class="product-info">
      <div class="product-view__image">
        <img
          *ngIf="product.images && localImages[product.images[0]]?.thumbnail"
          [src]="localImages[product.images[0]]?.thumbnail"
        />
      </div>

      <div class="wrap">
        <div class="product-view__name">{{ product.name }}</div>
        <div class="product-view__price">
          {{ utils.formatMoneyByMerchant(product.comboProduct.price, activeMerchant) }}
        </div>

        <div class="product-view__quantity">
          <div class="quantity-picker">
            <button (click)="decreaseQuantity(product)">-</button>
            <span>{{ product.comboProduct.quantity }}</span>
            <button (click)="increaseQuantity(product)">+</button>
          </div>
        </div>
      </div>
      <div class="remove" (click)="remove(i)">
        <img src="../../../assets/icon/combo-delete.svg" alt="" />
      </div>
    </div>
  </div>

  <button class="action-btn small" *ngIf="activeProducts.length < 5" (click)="openProductModal()">
    + Add Product
  </button>

  <button class="action-btn" style="margin-bottom: 20px" (click)="save()">
    <span *ngIf="isEdit">Update</span> <span *ngIf="!isEdit">Save</span>
  </button>
</ion-content>
