import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Files } from 'package-types';
import { Product } from 'src/app/models/Product';
import Utils from 'src/app/utils';

@Component({
  selector: 'app-combo-preview',
  templateUrl: './combo-preview.component.html',
  styleUrls: ['./combo-preview.component.scss'],
})
export class ComboPreviewComponent {
  public utils = Utils;
  @Input() combo: any;
  @Input() merchant: any;
  @Input() products: Product[] = [];
  @Input() imagesLinks: Files.Images.IdDimensionsLinks;

  @Output() edit: EventEmitter<void> = new EventEmitter();
  @Output() remove: EventEmitter<void> = new EventEmitter();

  get activeProducts(): Product[] {
    const comboProductIds = this.combo.products.map((comboProduct) => comboProduct.merProductId);

    const seenProductIds = new Set<string>();

    return this.products
      .filter((product) => comboProductIds.includes(product._id))
      .filter((product) => {
        if (seenProductIds.has(product._id)) {
          return false;
        }

        seenProductIds.add(product._id);
        return true;
      })
      .map((product) => ({
        ...product,
        comboProduct: this.combo.products.find(
          (comboProduct) => comboProduct.merProductId === product._id,
        ),
      }));
  }

  track(p) {
    return p._id;
  }

  get totals() {
    let originalTotal = 0;
    let comboTotal = 0;

    this.combo.products.forEach((product) => {
      comboTotal += parseFloat(product.price) * product.quantity;
      originalTotal +=
        parseFloat(this.products.find((p) => p._id === product.merProductId).price) *
        product.quantity;
    });

    return {
      originalTotal: originalTotal.toFixed(2),
      comboTotal: comboTotal.toFixed(2),
      factor: new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }).format((1 - comboTotal / originalTotal) * -1),
    };
  }
}
