import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  constructor(private modalController: ModalController) {}
  @Input('title') title: string;
  @Input('text') text: string;

  static async show(
    modalController: ModalController,
    title: string,
    text: string,
  ): Promise<{ success: boolean }> {
    const modal = await modalController.create({
      component: ConfirmationModalComponent,
      cssClass: 'confirmation-modal',
      backdropDismiss: false,
      componentProps: {
        title,
        text,
      },
    });
    await modal.present();
    return (await modal.onDidDismiss()).data;
  }

  ngOnInit() {}

  async select(status) {
    await this.modalController.dismiss({ success: status });
  }
}
