import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { ModalNavigationGuard } from './modal-navigation-guard';
import { ManualComponent } from '@pages/manual/manual.component';
import { CombosComponent } from '@pages/combos/combos.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthGuardService],
    canDeactivate: [ModalNavigationGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'manual',
    component: ManualComponent,
  },
  {
    path: 'fridge/:id',
    loadChildren: () => import('./pages/fridge/fridge.module').then((m) => m.FridgePageModule),
    canActivate: [AuthGuardService],
    canDeactivate: [ModalNavigationGuard],
  },
  {
    path: 'products',
    loadChildren: () => import('@pages/products/products.module').then((m) => m.ProductsPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'combos',
    component: CombosComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'weighting/:fridgeId/:productId',
    loadChildren: () =>
      import('./pages/weighting/weighting.module').then((m) => m.WeightingPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'recording/:fridgeId/:productId',
    loadChildren: () =>
      import('./pages/recording/recording.module').then((m) => m.RecordingPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'product/create',
    loadChildren: () =>
      import('./pages/product-form/product-form.module').then((m) => m.ProductFormPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'product/:merchantId/:productId/edit',
    loadChildren: () =>
      import('./pages/product-form/product-form.module').then((m) => m.ProductFormPageModule),
    canActivate: [AuthGuardService],
  },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
