import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { Files } from 'package-types';
import { Product } from 'src/app/models/Product';
import { ProductService } from 'src/app/services/product/product.service';

@Component({
  selector: 'app-select-product-modal',
  templateUrl: './select-product-modal.component.html',
  styleUrls: ['./select-product-modal.component.scss'],
})
export class SelectProductModalComponent implements OnInit {
  constructor(private modalCtrl: ModalController, public productService: ProductService) {}

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  @Input() merchantId;

  skip = 0;
  limit = 15;
  isSearch = false;
  debounce;
  filteredProducts: { imagesLinks: Files.Images.IdDimensionsLinks; products: Product[] };

  async ngOnInit() {
    this.productService.resetPagination();

    await this.productService.getPaginatedProducts(
      { skip: this.skip, limit: this.limit },
      this.infiniteScroll,
      this.merchantId,
    );
  }

  async doInfinite() {
    this.skip += this.limit;
    await this.productService.getPaginatedProducts(
      { skip: this.skip, limit: this.limit },
      this.infiniteScroll,
      this.merchantId,
    );
  }

  selectProduct(product, image) {
    this.modalCtrl.dismiss({ productData: { product, image } });
  }

  async back() {
    await this.modalCtrl.dismiss();
  }

  closeSearch() {
    this.isSearch = false;
  }
  async search(productName) {
    clearTimeout(this.debounce);
    if (productName.length === 0) {
      this.closeSearch();
      return;
    }
    this.debounce = setTimeout(async () => {
      this.isSearch = true;
      this.filteredProducts = await this.productService.getFilteredProducts({
        skip: 0,
        limit: 999,
        productName,
        merchantId: this.merchantId,
      });
    }, 300);
  }
}
