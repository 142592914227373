import { NgModule } from '@angular/core';
import { HeaderComponent } from '@components/header/header.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FridgeListComponent } from '@components/fridge-list/fridge-list.component';
import { FridgePickerComponent } from '@components/fridge-picker/fridge-picker.component';
import { FridgePickerModalComponent } from '@components/fridge-picker-modal/fridge-picker-modal.component';
import { WeightingAnimationComponent } from '@components/weighting-animation/weighting-animation.component';
import { SearchWrapComponent } from '@components/search-wrap/search-wrap.component';
import { CategoryPickerComponent } from '@components/category-picker/category-picker.component';
import { SwiperModule } from 'swiper/angular';
import { TranslateModule } from '@ngx-translate/core';
import { LanguagePickerComponent } from '@components/language-picker/language-picker.component';
import { FormInputModule } from "@components/form-input/form-input.module";

@NgModule({
  declarations: [
    HeaderComponent,
    FridgeListComponent,
    FridgePickerComponent,
    FridgePickerModalComponent,
    WeightingAnimationComponent,
    SearchWrapComponent,
    CategoryPickerComponent,
    LanguagePickerComponent,
  ],
  exports: [
    HeaderComponent,
    FridgeListComponent,
    FridgePickerComponent,
    FridgePickerModalComponent,
    WeightingAnimationComponent,
    SearchWrapComponent,
    CategoryPickerComponent,
    LanguagePickerComponent,
  ],
  imports: [IonicModule, FormsModule, CommonModule, SwiperModule, FormInputModule, TranslateModule],
})
export class SharedModule {}
