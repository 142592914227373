import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Product } from 'package-types/devices/socketEvents';

@Component({
  selector: 'app-simple-product',
  templateUrl: './simple-product.component.html',
  styleUrls: ['./simple-product.component.scss'],
})
export class SimpleProductComponent implements OnInit {
  constructor(public i18n: TranslateService) {}
  @Input('product') product: Product;
  @Input('image') image: string;

  @Output() select: EventEmitter<any> = new EventEmitter();

  ngOnInit() {}

  emitSelect() {
    this.select.emit(this.product);
  }
}
