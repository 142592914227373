import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input('title') title: String;
  @Output() back: EventEmitter<void> = new EventEmitter();

  onBack() {
    this.back.emit();
  }
}
