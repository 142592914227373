<div class="search-wrap" id="search" [class.open]="isSearch">
  <h2 (click)="onBack()">{{ title }}</h2>
  <div class="search-btn" (click)="openSearch()">
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.9526 18.8136L15.2936 14.1545C16.4484 12.7465 17.1445 10.943 17.1445 8.97729C17.1445 4.46851 13.4861 0.810059 8.97729 0.810059C4.46455 0.810059 0.810059 4.46851 0.810059 8.97729C0.810059 13.4861 4.46455 17.1445 8.97729 17.1445C10.943 17.1445 12.7425 16.4524 14.1505 15.2975L18.8096 19.9526C19.126 20.269 19.6362 20.269 19.9526 19.9526C20.269 19.6402 20.269 19.126 19.9526 18.8136ZM8.97729 15.519C5.36631 15.519 2.43164 12.5843 2.43164 8.97729C2.43164 5.37026 5.36631 2.43164 8.97729 2.43164C12.5843 2.43164 15.5229 5.37026 15.5229 8.97729C15.5229 12.5843 12.5843 15.519 8.97729 15.519Z"
        fill="white"
      />
    </svg>
  </div>

  <ion-input
    class="search-input"
    [(ngModel)]="searchQuery"
    #searchInput
    id="search-input"
    type="text"
    placeholder="Search"
    (ngModelChange)="search()"
  ></ion-input>

  <div class="close-btn" (click)="closeSearch()">
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.313532 14.1555C0.489471 14.3301 0.717651 14.4168 0.948416 14.4159C1.17626 14.415 1.40669 14.3265 1.58128 14.1506L6.74038 8.94868L11.9423 14.1107C12.1182 14.2853 12.3464 14.3721 12.5772 14.3712C12.8079 14.3703 13.0354 14.2818 13.21 14.1058C13.5592 13.754 13.557 13.1902 13.2052 12.841L8.00033 7.67899L13.1624 2.47708C13.5115 2.1252 13.5094 1.56144 13.1575 1.21226C12.8056 0.863081 12.2418 0.86525 11.8927 1.21713L6.73064 6.41904L1.52873 1.25702C1.17685 0.907837 0.613086 0.910007 0.263908 1.26188C-0.085271 1.61376 -0.0831012 2.17753 0.268776 2.52671L5.47069 7.68873L0.308664 12.8906C-0.0405143 13.2425 -0.0383332 13.8092 0.313532 14.1555Z"
        fill="#7B7B7B"
      />
    </svg>
  </div>
</div>
