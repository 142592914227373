import { ValidationErrors } from '@angular/forms';

export default class Utils {
  static getPredictedAvailability(currentQuantity, value, periodH) {
    if (!value && value !== 0) {
      if (currentQuantity) {
        return '1W+';
      } else {
        return 0;
      }
    }

    const hours = Math.round(value * periodH);
    const days = Math.round(hours / 24);

    if (hours >= 336) {
      return '2W+';
    } else if (days >= 2) {
      if (days === 7) {
        return '1W';
      } else if (days === 14) {
        return '2W';
      }
      return `${days}D`;
    }
    return `${hours}H`;
  }

  static getErrorMessage(validationErrors: ValidationErrors, label: string, i18n) {
    switch (Object.keys(validationErrors)[0]) {
      case 'backend':
        return validationErrors.backend;
      case 'required':
        return i18n.instant('edit_product.validation.required', { label });
      case 'pattern':
        switch (label) {
          case 'Barcode':
            return i18n.instant('edit_product.validation.barcode', { label });
          default:
            return i18n.instant('edit_product.validation.price', { label });
        }
      default:
        return 'Error';
    }
  }

  static formatMoneyByMerchant(decimalStringMoney, merchant) {
    const jurisdiction = merchant.jurisdiction || { currency: 'EUR' };

    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: jurisdiction.currency,
      minimumFractionDigits: 2,
    }).format(decimalStringMoney);
  }

  static capitalize(text: string): string {
    return `${text[0].toUpperCase()}${text.slice(1)}`;
  }

  static getCurrencySymbol(currency?: string) {
    switch (currency?.toUpperCase()) {
      case 'EUR':
        return '€';
      case 'CHF':
        return '₣';
      default:
        return '';
    }
  }
}
