<ion-header>
  <ion-row class="ion-justify-content-between ion-align-items-center">
    <div class="column">
      <div class="back" (click)="back()">
        <img src="./assets/icon/back.svg" alt="" />
      </div>
      <div class="logo">Manual</div>
    </div>
  </ion-row>
</ion-header>

<ion-content>
  <div class="manual-content">
    <p><img class="logo" title="" src="./assets/manual/image17.png" alt="" /></p>
    <p>This manual instructs on the proper use of the Smart Refrigerator - Smoothr Coolr.</p>
    <p>
      The Smoothr Coolr detects the products taken by customers and takes the correct amount from
      his payment method of choice. The use for the customer is very simple:
    </p>
    <ol start="1">
      <li>
        The customer presents his payment method at the card-reader or enters customer data if
        hospitality payment is enabled.
      </li>
      <li>An authorization is performed on the payment method and the door opens.</li>
      <li>
        The customer can take and put back products as he wishes. Cameras and weight sensors detect
        the products.
      </li>
      <li>The amount is shown on the tablet.</li>
      <li>The customer closes the door and the correct amount of money is taken.</li>
      <li>
        If the basket is incorrect, the customer can mark it as wrongly detected and it will be
        resolved manually by the support engineer. In this case money won&rsquo;t be reversed
        immediately.
      </li>
    </ol>
    <p>
      To enable the smooth working of the Smoothr Coolr the system needs to be operated correctly.
    </p>
    <p>This includes the creation of new products, the stocking and inventory management.</p>
    <h1>Important Definitions:</h1>
    <table>
      <tbody>
        <tr>
          <td colspan="1" rowspan="1">
            <p>Smoothr Coolr</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>
              Will be referred to with different terms: vending machine, Coolr, Smart Refrigerator
              and refers to all products of the product range including Double-Side, Dry Cabinets
              etc.
            </p>
          </td>
        </tr>
        <tr>
          <td colspan="1" rowspan="1">
            <p>EFT</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>
              The payment module used to scan the credit card or phone of the customer. Most used
              devices are the Feig from VR Payment or the Valina from Worldline
            </p>
          </td>
        </tr>
        <tr>
          <td colspan="1" rowspan="1">
            <p>Server</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>PC in the side/middle console</p>
          </td>
        </tr>
        <tr>
          <td colspan="1" rowspan="1">
            <p>Rack</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>Is a storage with shelves</p>
          </td>
        </tr>
        <tr>
          <td colspan="1" rowspan="1">
            <p>Shelf</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>Is a row in the coolr.</p>
          </td>
        </tr>
        <tr>
          <td colspan="1" rowspan="1">
            <p>Section</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>Is each compartment in the Coolr</p>
          </td>
        </tr>
      </tbody>
    </table>
    <p><br /><br /></p>
    <h1>Service-Man-App</h1>
    <p>
      The Service-Man-App is the tool to add new articles, stock the coolr and operate it generally.
    </p>
    <p>
      The App is reachable in any web-browser using the link:
      <a
        href="https://www.google.com/url?q=https://fridge.walkout.eu&amp;sa=D&amp;source=editors&amp;ust=1707491476285015&amp;usg=AOvVaw0mQCXhtGt9r6Uz99phlNRy"
        >https://fridge.walkout.eu
      </a>
    </p>
    <p>
      It is optimized for mobile. Contact
      <a href="mailto:support@smoothr.de">support@smoothr.de </a>if you are requiring access to the
      coolr
    </p>
    <h1>Creating Products</h1>
    <p>
      Products can be created without external support. The creation of new products is possible in
      4 easy steps. For the creation of new products it is necessary to have the product physically
      and be close to one of the devices.
    </p>
    <p>
      If you have multiple vending machines, it is enough to create the products once&nbsp;and you
      can sell them at any of the vending machines.
    </p>
    <h2>Step 1: Data-Input</h2>
    <p>Open the Service-Man-App and click on New Product:<br /></p>
    <p><img title="" src="./assets/manual/image6.png" alt="" /></p>
    <p>
      Next the system will ask you for the product information. This includes the following fields:
    </p>
    <p>All data can be edited afterwards.<br /><br /></p>
    <table>
      <tbody>
        <tr>
          <td colspan="1" rowspan="1">
            <p>Data Field</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>Description</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>Required</p>
          </td>
        </tr>
        <tr>
          <td colspan="1" rowspan="1">
            <p>Name</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>Name of the product that will be shown to the customer during the purchase</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>Yes</p>
          </td>
        </tr>
        <tr>
          <td colspan="1" rowspan="1">
            <p>Description</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>
              Additional information such as ingredients can be added here which will be also shown
              to the customer
            </p>
          </td>
          <td colspan="1" rowspan="1">
            <p>Yes</p>
          </td>
        </tr>
        <tr>
          <td colspan="1" rowspan="1">
            <p>Barcode</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>The Barcode number of the product for identification</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>Yes</p>
          </td>
        </tr>
        <tr>
          <td colspan="1" rowspan="1">
            <p>Article Number</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>The Number of the Article for your internal purposes(possible integrations)</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>No</p>
          </td>
        </tr>
        <tr>
          <td colspan="1" rowspan="1">
            <p>&nbsp;Price</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>
              Full Price including VAT without Deposit. Please separate the price with
              &ldquo;.&rdquo; not with &ldquo;,&rdquo;.
            </p>
          </td>
          <td colspan="1" rowspan="1">
            <p>Yes</p>
          </td>
        </tr>
        <tr>
          <td colspan="1" rowspan="1">
            <p>Category</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>The Category of the product</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>Yes</p>
          </td>
        </tr>
        <tr>
          <td colspan="1" rowspan="1">
            <p>Deposit</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>Will be added to the price</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>Yes</p>
          </td>
        </tr>
        <tr>
          <td colspan="1" rowspan="1">
            <p>Tax Rate</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>Tax Rate of the product for correct Reporting</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>Yes</p>
          </td>
        </tr>
        <tr>
          <td colspan="1" rowspan="1">
            <p>Age Restriction</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>If required the age verification. You can choose between 16 and 18 years old</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>No</p>
          </td>
        </tr>
        <tr>
          <td colspan="1" rowspan="1">
            <p>Image</p>
          </td>
          <td colspan="1" rowspan="1">
            <p>
              You can upload an image from your mobile or take one directly. Fotos also can be added
              after.
            </p>
          </td>
          <td colspan="1" rowspan="1">
            <p>No</p>
          </td>
        </tr>
      </tbody>
    </table>
    <h2>Step 2 Weighing:</h2>
    <p>
      After filling out the product information the product needs to be weighed. A correct weight
      measurement is necessary for a correct detection. The system will ask you if you want to weigh
      the product now.
    </p>
    <p><img title="" src="./assets/manual/image11.png" alt="" /></p>
    <p>Confirm and choose the desired Vending Machine where you want to weigh the product.</p>
    <p>Have the product in your hand prior to the next step.</p>
    <p>
      The door will open. Now you can put it on one of the shelves. It does not matter if there are
      products in the section.
    </p>
    <p>
      Repeat the process after the system shows you the weight three times and put it on different
      sections. Verify if the measurements are plausible and consistent.
    </p>
    <p>
      If you have products that deviate significantly from unit to unit &gt;50 grams please inform
      the support@smoothr.de
    </p>
    <p>After this process the products are ready to be sold.</p>
    <h2>Stocking</h2>
    <p>
      With the Service Man App you can easily stock and change the outlay of your vending machine.
    </p>
    <p>
      First you need to login and select the Coolr you want to stock. You will see a screen with the
      stock.
    </p>
    <p>
      If you have a Double Side Coolr, you need to select which side you want to open.<br /><img
        title=""
        src="./assets/manual/image9.png"
        alt=""
      />
    </p>
    <p>
      The screen is divided into Shelves (vertically) and Sections (horizontally). This is according
      to the vending machine - e.g. the product in the first shelf, first section will be the
      product in the top left of the Smoothr Coolr.
    </p>
    <p>
      If the section is not set-up yet in the service-man, a product needs to be added. Click on Add
      Product and search for the product you want to add. It does not matter if you filled the
      product into the section before or after selecting it in the Service Man App.<br /><br />
    </p>
    <p><img title="" src="./assets/manual/image4.png" alt="" /></p>
    <p>Only products that are trained will be shown here.</p>
    <p>
      If you want to put 2 products in one Section, click on + at the relevant section. If a + is
      not available please contact our support.<br />
    </p>
    <p><img title="" src="./assets/manual/image8.png" alt="" /></p>
    <p>
      You can add up to three different products in one section. We advise again putting products of
      the same weight and different price in one section.
    </p>
    <p>
      After you set-up the planogram, you can start filling the vending machine. Click on Open Door.
    </p>
    <p><img title="" src="./assets/manual/image15.png" alt="" /></p>
    <p>Very important! When filling the vending machine, please make sure that:<br /></p>
    <ul>
      <li>Products are put in the correct section</li>
      <li>Products don&rsquo;t touch walls</li>
      <li>Products don&rsquo;t touch the shelf above</li>
      <li>Products don&rsquo;t touch products&nbsp;in other sections (overlap)</li>
      <li>Products are not put in 2 different sections</li>
    </ul>
    <p>After you close the door, the inventory will be updated correctly.</p>
    <p><img title="" src="./assets/manual/image13.png" alt="" /></p>
    <p>
      If you notice that the inventory deviates significantly this means there could be an issue
      with the weight measurement or the sensor. Please inform our&nbsp;support in this case (<a
        href="mailto:support@smoothr.de"
        >support@smoothr.de).
      </a>
    </p>
    <p>
      If you have multiple products in one section you can click on edit section and set the
      inventory manually.
    </p>
    <p><img title="" src="./assets/manual/image18.png" alt="" /></p>
    <h2>Refilling</h2>
    <p>
      Click again on the fridge you want to refill. You can open the door and just put the products
      inside.
    </p>
    <p>
      If you want to change the product in one section, click on the product you want to replace:<br />
    </p>
    <p><img title="" src="./assets/manual/image1.png" alt="" /></p>
    <p>And click on Replace. Choose the product you want to put there.</p>
    <p>
      Another way to refill the inventory is by enabling service code on a tablet in a Service Man
      App. You will need to click on the enable button and choose 6+ digit code.
    </p>
    <p>
      <img title="" src="./assets/manual/image5.png" alt="" /><img
        title=""
        src="./assets/manual/image3.png"
        alt=""
      />
    </p>
    <p>
      Once it's done, you need to make 5 quick clicks directly on the top of the tablet screen to
      open the service screen. The spot where you need to click is painted yellow on the image
      below.
    </p>
    <p>
      <img title="" src="./assets/manual/image12.png" alt="" /><img
        title=""
        src="./assets/manual/image7.png"
        alt=""
      />
    </p>
    <p>
      Open the door and feel free to put products on the shelves. You will see on a tablet products
      that are on a sections and changes in the inventory that you make.
    </p>
    <p>
      <img title="" src="./assets/manual/image2.png" alt="" /><img
        title=""
        src="./assets/manual/image16.png"
        alt=""
      />
    </p>
    <h1>Back-Office</h1>
    <p>
      The Back-Office helps you to change product data, see sales and get reports. To access the
      Back-Office visit
      <a
        href="https://www.google.com/url?q=https://bo-board.walkout.eu&amp;sa=D&amp;source=editors&amp;ust=1707491476299846&amp;usg=AOvVaw1i-UglN44Rbz-7CgF5h-y5"
        >https://bo-board.walkout.eu&nbsp;
      </a>
    </p>
    <p>
      The login is the same as for the Service-Man-App. The Back-Office has a wide range of
      functions that can vary depending on the granted permissions.
    </p>
    <p>
      Please make sure that you inform us which permissions you want to grant each of the
      corresponding users.
    </p>
    <h2>Changing Prices:</h2>
    <p>Prices can be updated at Prices. Click on Prices:<br /></p>
    <p><img title="" src="./assets/manual/image10.png" alt="" /></p>
    <p>
      Search for the product where you want to edit the price. Click on Edit and change the
      Price.<br />
    </p>
    <p><img title="" src="./assets/manual/image14.png" alt="" /></p>
    <p>Prices will be updated immediately.</p>
    <h2>Sales/Exports:</h2>
    <p>
      If you want to review your relevant sales you can click on Sales. To generate an export of
      your sales you can just select the time frame, the devices you want to get the data for and
      click on Export.
    </p>
    <p><img title="" src="./assets/manual/image19.png" alt="" /></p>
    <p class="center">F&Uuml;R WEITERE FRAGEN STEHT IHR SMOOTHR TEAM ZUR VERF&Uuml;GUNG!</p>
    <p class="center">Sterlix GmbH</p>
    <p class="center">Christstra&szlig;e 38</p>
    <p class="center">14059 Berlin</p>
    <p class="center"><a href="mailto:support@smoothr.de">support@smoothr.de </a></p>
    <p class="center">030 31 196 544</p>
    <p class="center">https://smoothr.de</p>
    <div class="footer">
      <p class="center">Tel.: 030 31 196 544 &nbsp;</p>
      <p class="center">support@smoothr.de</p>
    </div>
  </div>
</ion-content>
