<div class="combo-products" *ngIf="activeProducts.length > 0">
  <div class="combo-products__total">
    <span class="old-total">
      {{ utils.formatMoneyByMerchant(totals.originalTotal, merchant) }}
    </span>
    <span class="new-total">
      {{ utils.formatMoneyByMerchant(totals.comboTotal, merchant) }}
      <span class="factor">({{ totals.factor }})</span>
    </span>
    <div class="buttons-row">
      <div class="combo-products__button" (click)="edit.emit(combo)">
        <img src="../../../assets/icon/combo-edit.svg" alt="" />
      </div>
      <div class="combo-products__button" (click)="remove.emit(combo._id)">
        <img src="../../../assets/icon/combo-delete.svg" alt="" />
      </div>
    </div>
  </div>
  <div class="combo-products-wrap">
    <div class="combo-products__item" *ngFor="let product of activeProducts; trackBy: track">
      <div class="combo-products__image">
        <img
          *ngIf="product.images && imagesLinks[product.images[0]]?.thumbnail"
          [src]="imagesLinks[product.images[0]]?.thumbnail"
        />
      </div>

      <div class="wrap">
        <div class="combo-products__name">{{ product.name }}</div>
        <div class="combo-products__price">
          {{ utils.formatMoneyByMerchant(product.comboProduct.price, merchant) }}
        </div>
      </div>

      <div class="combo-products__quantity">x{{ product.comboProduct.quantity }}</div>
    </div>
  </div>
</div>
