<ion-header>
  <ion-row class="ion-align-items-center">
    <div class="column">
      <div class="back" (click)="back()">
        <img src="assets/icon/back.svg" alt="" />
      </div>
      <div class="logo">
        <span *ngIf="isAdd">{{'replace_product.add_title' | translate}}</span>
        <span *ngIf="!isAdd">{{'replace_product.replace_title' | translate}}</span>
      </div>
    </div>
  </ion-row>
</ion-header>

<ion-content>
  <ion-row class="search-wrap ion-justify-content-between ion-align-items-center">
    <div *ngIf="!isSearch">
      <h2>{{'fridge.section'| translate}} <span>#{{ activeSection.number }}</span></h2>
      <div class="fridge-info">
        {{'fridge.rack'| translate}} #{{ rackNumber }}, {{'fridge.shelf'| translate}} #{{
        activeShelf.number }}, {{'fridge.fridge'| translate}} #{{
        fridgeService.activeFridge.inventoryNumber }}
      </div>
    </div>
    <app-search-wrap
      class="search"
      (close)="closeSearch()"
      (onSearch)="search($event)"
      title=""
    ></app-search-wrap>
  </ion-row>

  <div
    class="products"
    *ngIf="productService.paginatedImagesLinks && productService.paginatedProducts && !isSearch"
  >
    <app-replace-product-view
      *ngFor="let product of productService.paginatedProducts"
      [product]="product"
      [isAdd]="isAdd"
      [image]="
        product.images && productService.paginatedImagesLinks[product.images[0]]
          ? productService.paginatedImagesLinks[product.images[0]].thumbnail
          : ''
      "
      (replace)="replaceProduct(product)"
    ></app-replace-product-view>
  </div>

  <div
    class="products"
    *ngIf="isSearch && filteredProducts && filteredProducts.products.length > 0"
  >
    <app-replace-product-view
      *ngFor="let product of filteredProducts.products"
      [product]="product"
      [image]="
        product.images && filteredProducts.imagesLinks[product.images[0]]
          ? filteredProducts.imagesLinks[product.images[0]].thumbnail
          : ''
      "
      (replace)="replaceProduct(product)"
    ></app-replace-product-view>
  </div>

  <ion-infinite-scroll (ionInfinite)="doInfinite()" [disabled]="isSearch">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
