import { Injectable } from '@angular/core';
import { Api } from 'src/app/services/api/api.service';
import { DevicesWeighing, DevicesWeighingResult } from 'package-types/devices/events';
import { InstallType } from 'package-types/src/devices';

interface StartWeighing {
  installId: string;
  installType: InstallType;
  productId: string;
  rackNumber: number;
}
@Injectable({
  providedIn: 'root',
})
export class WeightingService {
  public weightingProcedure = false;
  public animationClass = '';
  public animationTimeout;
  public showIntermediateResultModal = false;
  public intermediateResultModalTimeout;
  public intermediateResult: DevicesWeighing | null = null;
  public finalWeight: null | number;

  public async startWeightingProcedure(data: StartWeighing) {
    const { success } = await Api.startWeightingProcess(data);
    if (success) this.weightingProcedure = true;
  }

  public async stopWeightingProcedure(data) {
    const { success } = await Api.stopWeightingProcess(data);
    if (success) this.weightingProcedure = false;
  }

  public setWeighingIntermediateResult(data, showModal = true) {
    this.intermediateResult = data;

    if (showModal) {
      clearTimeout(this.intermediateResultModalTimeout);
      this.showIntermediateResultModal = true;
      this.intermediateResultModalTimeout = setTimeout(() => {
        if (data.try !== data.tries) this.stepAnimation(data.try + 1);
        this.showIntermediateResultModal = false;
      }, 1000);
    } else {
      if (data.try !== data.tries) this.stepAnimation(data.try + 1);
    }
  }

  setWeighingResult(data: DevicesWeighingResult) {
    this.finalWeight = Math.round(data.weight);
  }

  startNewProcedure() {
    this.finalWeight = null;
    this.setWeighingIntermediateResult({ try: 0, tries: 3 } as DevicesWeighing, false);
  }

  resetWeightingProcedure() {
    this.finalWeight = null;
    this.intermediateResult = null;
    this.weightingProcedure = false;
  }

  public stepAnimation(step) {
    clearTimeout(this.animationTimeout);
    let duration = 0;
    switch (step) {
      case 1:
        duration = 2190;
        break;
      case 2:
        duration = 2690;
        break;
      case 3:
        duration = 2690;
        break;
      default:
        duration = 2200;
        break;
    }
    setTimeout(() => {
      this.animationClass = `animate-${step}-step`;
    }, 200);
    this.animationTimeout = setTimeout(() => {
      this.animationClass = `finish-${step}-step`;
    }, duration);
  }
}
